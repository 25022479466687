import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBookContext } from "../../BookContext";
import { fetchStoryLine, fetchStoryLines } from "../AIHelpAction";
import classes from "./Storyline.module.css";
import Select from "../../../../components/Select";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import Scenes from "./Scenes";
import Utils from "../../../../Utils";
import Plots from "./Plots";
import AIData from "../../../../components/AIData/AIData";

export default function Storyline({ setSection }) {
  const { storyLines, isStoryLineFetched } = useSelector((s) => s.aiHelp);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();
  const [selectedStoryline, setSelectedStoryline] = useState();

  const bookCtx = useBookContext();
  useEffect(() => {
    if (!storyLines?.length || !isStoryLineFetched) {
      fetchStoryLines(dispatch);
    } else {
      const quote =
        storyLines.find((q) => q.theme?.toLowerCase() === bookCtx.getTheme()?.toLowerCase()) || storyLines[0];
      fetchStoryLine(quote.id).then(setSelected);
    }
  }, [storyLines]);


  return (
    <div className={classes.container}>
      {/* <div className={classes.instruction}>Select Theme</div>
      <Select
        options={themeOptions}
        value={themeOptions.find((t) => t.value === selected?.id)}
        onChange={(e) => fetchStoryLine(e.value).then(setSelected)}
      /> */}
      <div className={classes.instruction}>
        Please select a Storyline
      </div>
      <div className={classes.storylineMain}>
        {selected?.values?.map((story, idx) => (
          <div className={Utils.getClasses(selectedStoryline === story && classes.selected)}
            onClick={(e) => { setSelectedStoryline(story) }}
            key={idx}
          >
            <AIData text={story.storyLine} />
          </div>
        ))}
      </div>
      <div className={classes.plotActions}>
        <div className={classes.plotInstruction}>
          Choose a Storyline and unlock more!
        </div>
        <div className={Utils.getClasses(classes.actionsContainer, !selectedStoryline && classes.disabled)} >
          <SecondaryButton className={classes.action} onClick={e => {
            setSection({
              key: "plots",
              value: "Plots",
              component: Plots,
              props: { values: selectedStoryline.plots }
            })
          }}>Plots</SecondaryButton>
          <SecondaryButton className={classes.action} onClick={e => {
            setSection({
              key: "scenes",
              value: "Scenes",
              component: Scenes,
              props: { values: selectedStoryline.scenes }
            })
          }}>Scenes</SecondaryButton>
        </div>
      </div>
    </div>
  );
}
