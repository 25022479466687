import Utils from "../../../Utils";
import classes from "../HomeScreen.module.css";

function CarouselItem({ item, idx, currentItem }) {
  const className =
    currentItem === idx ? classes.whoAreWeCardActive : classes.whoAreWeCard;
  const displayClass = item.display ? classes.displayNone : "";
  return (
    <div
      key={idx}
      className={Utils.getClasses(className, displayClass)}
      style={currentItem === idx ? { background: item.highlightColor } : {}}
    >
      <div
        className={classes.whoAreWeCardImage}
        style={
          currentItem === idx
            ? { background: "white" }
            : { background: "rgb(255, 218, 170" }
        }
      >
        <div>
          <img src={item.image} alt="themes" />
        </div>
      </div>
      <div className={classes.whoAreWeCardTitle}>
        <div>{item.title}</div>
      </div>
    </div>
  );
}

export default CarouselItem;
