import React, { useEffect, useRef, useState } from "react";
import classes from "./Invoice.module.css";
import UserDashboardHeader from "../UserDashboardHeader/UserDashboardHeader";
import { getOrderById } from "../../../Books/BooksActions";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceItems from "./InvoiceItems";
import InvoicePaymentDetails from "./InvoicePaymentDetails";
import InvoiceFooter from "./InvoiceFooter";
import { useReactToPrint } from "react-to-print";
import InvoiceThanks from "./InvoiceThanks";
import BackButton from "../../../../components/Buttons/BackButton";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchTransferredEarningById } from "../../../Users/UsersActions";
import Utils from "../../../../Utils";

function Invoice({ match: { params } }) {
  const id = params.id;
  const contentRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    bodyClass: classes.printBody,
  });

  const [data, setData] = useState({});

  const type = searchParams.get("type");

  useEffect(() => {
    if (!type) {
      getOrderById(id).then((e) => {
        setData(e.data.data);
      });
    } else {
      fetchTransferredEarningById(id).then((e) => {
        if (e.data.success) {
          let data = e.data.data;
          data.items = [
            {
              title: `Royalty earned on ${Utils.getFormattedDate(
                data.creationTime
              )}`,
              price: data.amount,
              quantity: 1,
              totalAmount: data.amount,
            },
          ];
          setData(data);
        }
      });
    }
  }, [id, type]);

  const subtotal = data?.items?.reduce(
    (acc, book) => acc + book.totalAmount,
    0
  );
  const proofreading = data?.items?.reduce((acc, book) => {
    if (book.isProofReadingService) {
      const pages = book.totalPages;
      const proofreadingFee =
        pages <= 64 ? 100 : pages > 64 && pages <= 120 ? 200 : 350;
      return acc + proofreadingFee;
    }

    return acc + 0;
  }, 0);
  const listing = data?.items?.reduce((acc, book) => {
    if (book.isListingService) {
      return acc + 100;
    }

    return acc + 0;
  }, 0);

  return (
    <div className={classes.main}>
      <BackButton className={classes.backButton} />
      <UserDashboardHeader
        title="Invoice Details"
        description="Get your order details"
        buttonText="Print / Download Invoice"
        onButtonClick={reactToPrintFn}
      />
      <div id="invoice" className={classes.invoiceContainer} ref={contentRef}>
        <InvoiceThanks type={type} />
        <InvoiceHeader data={data} type={type} />
        <InvoiceItems items={data?.items} type={type} />
        <InvoicePaymentDetails
          paymentMethod={""}
          type={type}
          shipping={data?.shippingCharge || 0}
          subtotal={parseInt(subtotal) || 0}
          total={data?.amount || 0}
          tax={0}
          listing={listing}
          proofreading={proofreading}
        />
        <InvoiceFooter />
      </div>
    </div>
  );
}

export default Invoice;
