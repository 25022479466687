import React from 'react'
import classes from './Dashboard.module.css'

function DashboardInput({ onChange, type, id, max, min, className, placeholder, inputDisabled, disabled, label, inputStyle, onPaste, value, onKeyDown }) {

    return <div className={classes.main}>
        <label htmlFor={id} className={classes.userDetailsLabel}>{label}</label>
        {
            disabled ? <label className={className}>{placeholder}</label> : <input type={type}
                style={{ borderRadius: '4px', ...inputStyle }}
                id={id}
                className={className || classes.inputFelid}
                onPaste={onPaste}
                disabled={inputDisabled}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onKeyDown={onKeyDown}
                max={max}
                min={min}
            />
        }
    </div>

}

export default DashboardInput