import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import seo from "../../seo.json";
import { useSelector } from "react-redux";

function HelmetProvider() {
  const location = useLocation();
  const currentPath = location.pathname.slice(1);

  const product = useSelector((state) => state.books.publishedBook);
  const books = useSelector((state) => state.books.storeBooks);

  const memoizedProduct = useMemo(() => product, [product]);
  const memoizedBooks = useMemo(() => books, [books]);

  const [seoData, setSeoData] = useState({
    title: "",
    description: "",
    keywords: "",
    schema: "",
  });

  useEffect(() => {
    if (currentPath.includes("details") && memoizedProduct) {
      setSeoData({
        title: memoizedProduct?.title,
        description: memoizedProduct?.description,
        keywords: memoizedProduct?.keywords,
        schema: {
          "@context": "https://schema.org/",
          "@type": "Product",
          name: memoizedProduct?.title,
          image: `${window.origin}${memoizedProduct?.frontThumbURL}?${memoizedProduct?.updateDate}`,
          description: memoizedProduct?.description,
          sku: memoizedProduct?.id,
          brand: {
            "@type": "Brand",
            name: "Bookalooza",
          },
          offers: {
            "@type": "Offer",
            url: window.location.href,
            priceCurrency: "INR",
            price: memoizedProduct?.regularPrice,
            priceValidUntil: "2024-12-31",
            itemCondition: "https://schema.org/NewCondition",
            availability: "https://schema.org/InStock",
          },
        },
      });
    } else if (currentPath.includes("store") && memoizedBooks) {
      setSeoData({
        title: "Store",
        description: "Browse our store",
        keywords: "store, books",
        schema: {
          "@context": "https://schema.org/",
          "@type": "ItemList",
          itemListElement: memoizedBooks?.map((e, index) => ({
            "@type": "ListItem",
            position: index + 1,
            item: {
              "@type": "Book",
              name: e.title,
              author: e.author,
            },
          })),
        },
      });
    } else {
      setSeoData(seo[currentPath] || seo.base);
    }
  }, [currentPath, memoizedProduct, memoizedBooks]);

  return (
    <Helmet>
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      <meta name="keywords" content={seoData.keywords} />
      <script type="application/ld+json">{JSON.stringify(seoData.schema)}</script>
    </Helmet>
  );
}

export default HelmetProvider;