import React from "react";
import classes from "../HomeScreen.module.css";
import SeoBannerImg from "../../../assets/images/seobanner.png";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { useHistory } from "react-router-dom";
import Utils from "../../../Utils";

function SeoBanner() {
  const history = useHistory();
  return (
    <div className={classes.seoBannerMain}>
      <div className={classes.seoHeader}>
        <div className={classes.seoImageContainer}>
          <img src={SeoBannerImg} alt="seoBanner" />
        </div>
        <div className={classes.seoHeaderContent}>
          <p>Write Your Book Today,</p>
          <h4>Earn Royalties for a Lifetime!</h4>
          <div>
            <PrimaryButton
              className={classes.seoCreateButton}
              onClick={() => {
                Utils.createBook(history);
              }}
            >
              Create you book
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={classes.seoContent}>
        <div>
          If you're an aspiring storyteller, Bookalooza is the ultimate platform
          to help you{" "}
          <b>
            <em>write, publish, and earn—all</em>
          </b>{" "}
          for free. Struggling to find tools for{" "}
          <b>
            <em>book writing for children,</em>
          </b>{" "}
          an intuitive book{" "}
          <b>
            <em>writing platform for writers</em>
          </b>
          , or guidance on how to write books online? Bookalooza has you
          covered!
        </div>
        <div>
          With amazing in-built tools, umpteen themes and templates, and the
          ability to{" "}
          <b>
            <em>earn money through book</em>
          </b>{" "}
          writing, Bookalooza ensures your passion turns into published works
          loved by readers worldwide. Plus, by writing your book on
          Bookalooza,you can earn{" "}
          <b>
            <em>10% royalty for a lifetime</em>
          </b>
          —because your creativity deserves recognition and reward.
        </div>
      </div>
    </div>
  );
}

export default SeoBanner;
