import { useEffect, useState } from "react";

import classes from "./SearchBar.module.css";
import searchIcon from "../../assets/icons/search.svg";
import Utils from "../../Utils";

const SearchBar = ({
  debounce = true,
  className,
  placeholder,
  greyBackground,
  onSearch,
  search = ""
}) => {
  const [searchText, setSearchText] = useState(search);

  useEffect(() => {
    setSearchText(search);
  }, [search])

  useEffect(() => {
    if (onSearch) {
      if (debounce) {
        const timeout = setTimeout(() => {
          onSearch(searchText);
        }, 1000);
        return () => clearTimeout(timeout);
      } else {
        onSearch(searchText);
      }
    }
  }, [searchText, debounce]);

  return (
    <div
      className={Utils.getClasses(
        classes.searchBar,
        greyBackground ? classes.greyBackground : "",
        className
      )}>
      <img
        src={searchIcon}
        alt='Search'
        onClick={() => onSearch && onSearch(searchText)}
      />
      <input
        type='text'
        placeholder={placeholder || "Search"}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={(e) => (e.keyCode === 13 && onSearch) && onSearch(searchText)}
      />
    </div>
  );
};

export default SearchBar;
