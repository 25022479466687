import React from "react";
import classes from "../HomeScreen.module.css";
import AnimatedList from "../../../components/AnimatedList/AnimatedList";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Marquee from "react-fast-marquee";
import b2bImage from "../../../assets/images/b2b.jpeg";
import b2bImage1 from "../../../assets/images/Image-1.jpg";
import b2bImage2 from "../../../assets/images/Image-2.jpg";
import b2bImage3 from "../../../assets/images/Image-3.jpg";
import b2bImage4 from "../../../assets/images/Image-4.jpg";
import b2bImage5 from "../../../assets/images/Image-5.jpg";

const items = [
  "Get in touch with us for exclusive school deals",
  <>
    Schedule a <b><em>FREE Creative Storytelling Workshop</em></b> for your students
  </>,
  <>Watch your students write, publish, and earn through their books</>,
  "Celebrate their stories and share their achievements",
];

const marqueeItems = [
  {
    img: b2bImage
  },
  {
    img: b2bImage1
  }, 
  {
    img: b2bImage2
  },
  {
    img: b2bImage3
  },
  {
    img: b2bImage4
  },
  {
    img: b2bImage5
  },
];

function B2BInfo() {
  return (
    <div className={classes.b2bInfoContainer}>
      <div className={classes.b2bContentWrapper}>
        <div className={classes.b2bBannerImageWrapper}>
          <Marquee className={classes.b2bMarquee} speed={90}>
            {marqueeItems.map((e, idx) => (
              <div className={classes.b2bMarqueeChild} key={idx}>
                <img src={e.img} alt={e.img} />
              </div>
            ))}
          </Marquee>
        </div>
        <div className={classes.b2bContent}>
          <div className={classes.b2bHeader}>
            <h1>Exclusive Deals for Schools!</h1>
            <h2>Partner with Bookalooza. Create Star Authors!</h2>
            <p>
              Partner with us to get <b><em>special discounts</em></b> for your school
              and a <b><em>FREE workshop</em></b> on the art of creative storytelling.
              Let’s help your students turn their ideas into incredible books
              and showcase their talent.
            </p>
          </div>

          <div className={classes.b2bWork}>
            <h4>Here’s how it works: </h4>
            <AnimatedList listItems={items} className={classes.b2bWorkList} />
          </div>

          <PrimaryButton
            className={classes.b2bButton}
            onClick={() =>
              window.open(
                `https://wa.me/918799721408?text=${encodeURIComponent(
                  "Hi I want to know more"
                )}`
              )
            }
          >
            Enquire Now!
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default B2BInfo;
