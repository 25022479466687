import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchEarnings,
  fetchTransferredEarnings,
} from "../../../Users/UsersActions";
import classes from "./Earning.module.css";
import UserDashboardHeader from "../UserDashboardHeader/UserDashboardHeader";
import EmptyDialogue from "../EmptyDialogue/EmptyDialogue";
import wallet from "../../../../assets/dashboardImage/animation/earnings-wallet.json";
import Utils from "../../../../Utils";
import ClaimForm from "./ClaimForm/ClaimForm";
import { getBankDetails } from "../BankDetails/BankActions";

function Earnings() {
  const history = useHistory();
  const userDetails = useSelector((state) => state.user);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(totalEarnings);
  const [transferredAmount, setTransferredAmount] = useState(0);
  const [earnings, setEarnings] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userBankDetails, setUserBankDetails] = useState({});

  useEffect(() => {
    setRemainingAmount(totalEarnings - transferredAmount);
  }, [totalEarnings, transferredAmount]);

  useEffect(() => {
    if (userDetails.user) {
      let filter = {
        where: {userId: userDetails.user.userId }
      };
      fetchEarnings(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
          const totalEarnings = data?.data
            .map((elem) => elem.totalAmount)
            .reduce((a, b) => a + b, 0);
          setEarnings(data.data);
          setTotalEarnings(totalEarnings);
        }
      });

      fetchTransferredEarnings({
        where: {
          ...filter.where,
          status: { $in: ["processing", "processed"] },
        },
      }).then(function (response) {
        let data = response.data;
        if (data.success) {
          const totalEarnings = data?.data
            .map((elem) => elem.amount)
            .reduce((a, b) => a + b, 0);

          setTransferredAmount(totalEarnings);
        }
      });

      getBankDetails(filter).then(function (response) {
                      let data = response.data;
                      if (data.success) {
                          setUserBankDetails(data.data[0])
                      }
                  })
    }
  }, [userDetails]);

  const createBookHandler = () => {
    Utils.createBook(history);
  };

  console.log(userBankDetails)

  return (
    <div className={classes.main}>
      <UserDashboardHeader
        key={"User Header"}
        title="Your Earnings"
        description="Manage your earnings here."
        disabledButton={remainingAmount < 1000 || !userBankDetails?.accountNumber}
        buttonText="Transfer to my bank"
        onButtonClick={() => setIsOpen((p) => !p)}
      />
      {!totalEarnings ? (
        <EmptyDialogue
          key={"Empty message"}
          animation={wallet}
          descriptionText="No earnings yet! Publish a book and start earning."
          buttonText="Create your free book now"
          onClick={createBookHandler}
        />
      ) : (
        <div className={classes.container}>
          <div className={classes.earningWrapper}>
            <div className={classes.totalEarnings}>
              <h1 className={classes.totalEarningHeading}>
                Your Total Earning
              </h1>
              <div className={classes.amountWrapper}>
                <p className={classes.amount}>
                  {Utils.getRupeeSymbol} {totalEarnings.toFixed(2)}
                </p>
              </div>
            </div>
            <div className={classes.transferredAmount}>
              <h1 className={classes.transferredAmountHeading}>
                Transferred Amount
              </h1>
              <div className={classes.amountWrapper}>
                <p className={classes.amount}>
                  {Utils.getRupeeSymbol} {transferredAmount.toFixed(2)}
                </p>
              </div>
            </div>
            <div className={classes.remainingAmounts}>
              <h1 className={classes.remainingAmountHeading}>
                Remaining Amount
              </h1>
              <div className={classes.amountWrapper}>
                <p className={classes.amount}>
                  {Utils.getRupeeSymbol} {remainingAmount.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          {isOpen ? (
            <ClaimForm
              onCancel={() => setIsOpen(false)}
              remainingAmount={remainingAmount}
            />
          ) : (
            <>
              <div className={classes.disclaimerWrapper}>
                <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
                <span>
                  Disclaimer : A minimum earning of Rs. 1000 is required to
                  withdraw your money. See your transferred earnings{" "}
                  <Link to="/user/transferred-earnings">click here</Link>. Sell
                  more | Earn more
                </span>
              </div>
              <div className={classes.earningsTableWrapper}>
                <table className={classes.earningTable}>
                  <thead className={classes.earningTableHeading}>
                    <tr className={classes.headingRow}>
                      <th className={classes.bookTitleHeadingCell}>
                        Book Title Name
                      </th>
                      <th className={classes.copiesSold}>Copies Sold</th>
                      <th className={classes.earningHeadingCell}>Earning</th>
                    </tr>
                  </thead>
                  <tbody>
                    {earnings?.map((earning, orderIdx) => {
                      const bookDetails = earning.bookDetails[0];
                      return (
                        <tr className={classes.productRow} key={orderIdx}>
                          <td className={classes.bookNameCell}>
                            {bookDetails?.title || "Untitled"}
                          </td>
                          <td className={classes.bookCopies}>
                            {earning.totalCount}
                          </td>
                          <td className={classes.bookPrice}>
                            {Utils.getRupeeSymbol}{" "}
                            {earning.totalAmount?.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default Earnings;
