import React, { Suspense } from 'react';
import { useEffect } from 'react';
import { useHistory } from "react-router"
import { useDispatch, useSelector } from 'react-redux';
import classes from './NewBook.module.css';
import ThemeItem from '../../../components/ThemeItem/ThemeItem';
import { booksActions } from '../BooksSlice';
import BackButton from '../../../components/Buttons/BackButton';
import Utils from '../../../Utils';
import { fetchAllThemes } from './ThemeActions';
import { fetchStoryLines } from '../../Editor/AIHelp/AIHelpAction';

const Editor = React.lazy(() => import('../../Editor/Editor'));

export default function NewBook() {
    const dispatch = useDispatch();
    const history = useHistory();
    const book = useSelector(state => state.books.book);

    useEffect(() => {
        dispatch(booksActions.fetchBook({}));
        fetchAllThemes(dispatch);
    }, [dispatch]);

    const {allThemes, trendingThemes, popularThemes} = useSelector(state => state.themes);

    const handleThemeClick = (e) => {
        const book = {
            theme: e.name
        };
        history.push({ search: new URLSearchParams(book).toString() })
        dispatch(booksActions.fetchBook({ book }));
    }

    return <>
        {
            book ? <Suspense fallback={<>Loading...</>}>
                <Editor book={book} />
            </Suspense> :
                <div className={classes.mainContainer}>
                    <div className={classes.paddedContainer}>
                        <BackButton />
                        <div className={classes.heading}>
                            <h1 className={classes.headerText}>Choose a theme for your book</h1>
                        </div>
                        <div className={classes.innerContainer}>
                            <div className={Utils.getClasses(classes.trendingThemes, classes.themesWrapper)}>
                                <h3 className={classes.sectionHeading}>Trending themes</h3>
                                <Themes
                                    scrollBar
                                    themeItems={trendingThemes}
                                    onSelectionChange={handleThemeClick}
                                />
                            </div>
                            <div className={Utils.getClasses(classes.mostPopularThemes, classes.themesWrapper)}>
                                <h3 className={classes.sectionHeading}>Most popular themes</h3>
                                <Themes
                                    scrollBar
                                    themeItems={popularThemes}
                                    onSelectionChange={handleThemeClick}
                                />
                            </div>
                            <div className={Utils.getClasses(classes.allThemes, classes.themesWrapper)}>
                                <h3 className={classes.sectionHeading}>All themes</h3>
                                <div className={classes.container}>
                                    <div className={classes.allThemesContainer}>
                                        <Themes
                                            scrollBar
                                            themeItems={allThemes}
                                            themeItemStyle={{ background: 'rgba(246, 246, 246, 1)' }}
                                            onSelectionChange={handleThemeClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
    </>;
};

const Themes = ({ themeItems, selectedTheme, onSelectionChange, themeItemStyle, scrollBar, onDoubleClick }) => {
    const {storyLines, isStoryLineFetched} = useSelector((s) => s.aiHelp);
    const dispatch = useDispatch();

    console.log(isStoryLineFetched)

    useEffect(() => {
        if(!isStoryLineFetched) fetchStoryLines(dispatch);
    }, [dispatch, isStoryLineFetched]);

    const aiThemes = new Set(storyLines?.map(i => i.theme.toLowerCase()));
    
    return (
        <div className={Utils.getClasses(scrollBar ? "scroll-vertical scroll-style" : classes.themesContainerWrapper, scrollBar && classes.scrollBarForThemes)}>
            <div className={classes.themes}>
                {
                    themeItems.map((themeItem) =>
                        <ThemeItem
                            themeImage={themeItem?.displayImage}
                            key={themeItem?.name}
                            themeName={themeItem?.name}
                            onDoubleClick={() => {
                                onSelectionChange(themeItem);
                                onDoubleClick();
                            }}
                            onClick={() => onSelectionChange(themeItem)}
                            selected={themeItem === selectedTheme}
                            style={{ ...themeItemStyle }}
                            ai={aiThemes.has(themeItem.name.toLowerCase())}
                        />
                    )
                }
            </div>
        </div>)
};
