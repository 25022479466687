import React from "react";
import classes from "../HomeScreen.module.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Utils from "../../../Utils";
import heroVideo from "../../../assets/video/heroevent.webm";
import heroImg from "../../../assets/hero.webp";

function HeroSection() {
  const history = useHistory();
  const theme = useTheme();
  const isMobScreen = !useMediaQuery(theme.breakpoints.up("sm"));

  const createBookHandler = () => {
    Utils.createBook(history);
  };

  return (
    <div className={classes.heroImageContainer}>
      <video
        style={{ width: "100%", height: "100%" }}
        loop
        autoPlay
        muted
        playsInline
        loading="lazy"
        poster={heroImg}
        preload="metadata"
        onLoad={() => console.log("loaded")}
      >
        <source src={heroVideo} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <div
        className={
          isMobScreen ? classes.createMobileButton : classes.createButton
        }
      >
        <PrimaryButton onClick={createBookHandler}>
          Become an Author
        </PrimaryButton>
      </div>
    </div>
  );
}

export default HeroSection;
