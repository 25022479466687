import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quotes: [],
  wordList: [],
  names: [],
  storyLines: [],
  isStoryLineFetched: false,
  characters: []
};

const uiHelpSlice = createSlice({
  name: "aiHelp",
  initialState,
  reducers: {
    fetchQuotes: (state, action) => {
      state.quotes = action.payload;
    },
    fetchWordLists: (state, action) => {
      state.wordList = action.payload;
    },
    fetchNames: (state, action) => {
      state.names = action.payload;
    },
    fetchStoryLines: (state, action) => {
      state.storyLines = action.payload;
      state.isStoryLineFetched = true;
    },
    fetchCharacters: (state, action) => {
      state.characters = action.payload;
    }
  },
});

export const uuiHelpAction = uiHelpSlice.actions;
export default uiHelpSlice.reducer;
