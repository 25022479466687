import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./UserDashboard.module.css";
import writingBook from "../../../../assets/dashboardImage/writing-book-icon.png";
import readingBook from "../../../../assets/dashboardImage/reading-book-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import UserDashboardHeader from "../UserDashboardHeader/UserDashboardHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchEarnings } from "../../../Users/UsersActions";
import Utils from "../../../../Utils";
import Consts from "../../../../Consts";

function Dashboard() {
  const history = useHistory();
  const userDetails = useSelector((state) => state.user);
  const [totalEarnings, setTotalEarnings] = useState(0);

  const createBookHandler = () => {
    Utils.createBook(history);
  };

  useEffect(() => {
    if (userDetails.user) {
      let filter = {};
      fetchEarnings({
        ...filter,
        where: { ...filter?.where, userId: userDetails.user.userId },
      }).then(function (response) {
        let data = response.data;
        if (data.success) {
          const totalEarnings = data.data
            .map((elem) => elem.totalAmount)
            .reduce((a, b) => a + b, 0);
          setTotalEarnings(totalEarnings);
        }
      });
    }
  }, [userDetails]);

  return (
    <div className={classes.main}>
      <UserDashboardHeader
        title="Your dashboard"
        description="Manage your account details here."
        disabled
      />
      <div className={classes.container}>
        <div
          className={classes.cards + " cursorPointer"}
          onClick={createBookHandler}
        >
          <div className={classes.cardIconWrapper}>
            <img
              src={writingBook}
              alt="cardIcon"
              className={classes.cardIcon}
            />
          </div>
          <div className={classes.headingAndContentWrapper}>
            <h1 className={classes.cardHeading}>START WRITING</h1>
            <div className={classes.cardContentWrapper}>
              <p>Create your free book now!</p>
              <div className={classes.forwardButtonIcon}>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          </div>
        </div>
        <div
          className={classes.cardsDark + " cursorPointer"}
          onClick={() => Utils.openBookstore(history)}
        >
          <div className={classes.cardIconWrapper}>
            <img
              className={classes.cardIcon}
              src={readingBook}
              alt="cardIcon"
            />
          </div>
          <div className={classes.headingAndContentWrapper}>
            <h1 className={classes.cardHeading}>START READING</h1>
            <div className={classes.cardContentWrapper}>
              <p>Take a look at our BookStore</p>
              <div className={classes.forwardButtonIcon}>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={classes.earningsCard + " cursorPointer"}
            onClick={() => history.push(`${Consts.BASE_URL}/user/earnings`)}
          >
            <div className={classes.earningHeadingAndPriceWrapper}>
              <h1 className={classes.earningHeading}>Your Total Earnings:</h1>
              <p className={classes.earningPrice}> RS. {totalEarnings.toFixed(2)}</p>
            </div>
            <div className={classes.forwardButtonIconBigWrapper}>
              <div className={classes.forwardButtonIconBig}>
                <FontAwesomeIcon icon={faAngleRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
