import React from "react";

const Icons = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="14.883" height="16.425" viewBox="0 0 14.883 16.425" stroke="#000">
        <g id="Group_189853" dataName="Group 189853" transform="translate(-1247.583 -217)">
            <path id="Path_35835" dataName="Path 35835" d="M4.5,8.4,11.441,3l6.941,5.4v8.484a1.543,1.543,0,0,1-1.543,1.543H6.043A1.543,1.543,0,0,1,4.5,16.883Z" transform="translate(1243.583 214.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
            <path id="Path_35836" dataName="Path 35836" d="M13.5,25.713V18h4.628v7.713" transform="translate(1239.583 207.213)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
        </g>
    </svg>)
};

Icons.Achievements = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trophy">
            <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"/>
            <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"/>
            <path d="M4 22h16"/>
            <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"/>
            <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"/>
            <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"/>
        </svg>
    )
}

Icons.Profile = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-round">
            <circle cx="12" cy="8" r="5" />
            <path d="M20 21a8 8 0 0 0-16 0" />
        </svg>
    )
}

Icons.Library = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-book">
            <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20"/>
        </svg>
    )
}

Icons.History = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
            <path d="M3 3v5h5" />
            <path d="M12 7v5l4 2" />
        </svg>
    )
};

Icons.Bank = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-piggy-bank">
            <path d="M19 5c-1.5 0-2.8 1.4-3 2-3.5-1.5-11-.3-11 5 0 1.8 0 3 2 4.5V20h4v-2h3v2h4v-4c1-.5 1.7-1 2-2h2v-4h-2c0-1-.5-1.5-1-2V5z" />
            <path d="M2 9v1c0 1.1.9 2 2 2h1" />
            <path d="M16 11h.01" />
        </svg>
    )
}

Icons.Earnings = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20.48" height="17.422" viewBox="0 0 20.48 17.422">
            <g id="Group_189861" dataName="Group 189861" transform="translate(-1351.662 -216.915)">
                <g id="Group_189721" dataName="Group 189721" transform="matrix(0.883, 0.469, -0.469, 0.883, 1356.607, 217.254)"  >
                    <g id="Group_189859" dataName="Group 189859" transform="translate(0 0)">
                        <path id="Path_42702" dataName="Path 42702" d="M8.6,0q3.454,0,6.908,0a1.617,1.617,0,0,1,1.666,1.3,2.049,2.049,0,0,1,.033.413q0,3.195,0,6.39a1.612,1.612,0,0,1-1.271,1.67,1.974,1.974,0,0,1-.429.04q-6.9,0-13.8,0A1.618,1.618,0,0,1,0,8.111Q0,4.907,0,1.7A1.619,1.619,0,0,1,1.712,0H8.6m0,.519q-3.411,0-6.821,0A1.841,1.841,0,0,0,1.34.564,1.088,1.088,0,0,0,.524,1.7q0,3.2,0,6.406,0,.078,0,.156a1.059,1.059,0,0,0,.425.8,1.251,1.251,0,0,0,.815.232H15.444c.058,0,.116,0,.173,0a1.087,1.087,0,0,0,1.07-1.128c0-.219,0-.439,0-.658q0-2.883,0-5.766a1.237,1.237,0,0,0-.164-.7A1.142,1.142,0,0,0,15.48.518q-3.437,0-6.873,0" transform="translate(0 0)" strokeWidth="0.5" />
                        <path id="Path_42704" dataName="Path 42704" d="M42.317,16.861h-.638c-.34,0-.681,0-1.021,0-.184,0-.288-.1-.293-.248s.1-.269.291-.27c.485,0,.969,0,1.454,0h.19a.719.719,0,0,0-.631-.564c-.3-.028-.6-.016-.9-.022-.069,0-.156.019-.2-.015-.081-.057-.183-.146-.189-.228s.087-.18.156-.252c.031-.032.109-.026.166-.026q1.289,0,2.579,0a.691.691,0,0,1,.1,0,.255.255,0,0,1,.24.268.246.246,0,0,1-.259.246c-.2.007-.4,0-.606,0-.039,0-.079,0-.126.007l.267.58c.119,0,.267,0,.415,0,.2.005.314.106.309.271a.248.248,0,0,1-.309.245.474.474,0,0,0-.576.352,1.226,1.226,0,0,1-1.149.755c-.13.007-.262,0-.424,0,.045.068.07.111.1.149q.46.6.92,1.191c.128.166.125.328,0,.426s-.272.066-.4-.1q-.67-.86-1.336-1.723c-.076-.1-.127-.217-.044-.316a.427.427,0,0,1,.269-.142c.3-.018.611,0,.917-.011a.8.8,0,0,0,.732-.58" transform="translate(-33.387 -12.6)" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

Icons.Notifications = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-bell">
            <path d="M10.268 21a2 2 0 0 0 3.464 0" />
            <path d="M3.262 15.326A1 1 0 0 0 4 17h16a1 1 0 0 0 .74-1.673C19.41 13.956 18 12.499 18 8A6 6 0 0 0 6 8c0 4.499-1.411 5.956-2.738 7.326" />
        </svg>
    )
};

Icons.Logout = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out">
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/>
            <polyline points="16 17 21 12 16 7"/>
            <line x1="21" x2="9" y1="12" y2="12"/>
        </svg>
    )
}

Icons.Royalty = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-left-right">
            <path d="M8 3 4 7l4 4"/>
            <path d="M4 7h16"/>
            <path d="m16 21 4-4-4-4"/>
            <path d="M20 17H4"/>
        </svg>
    )
}

export default Icons;
