import React, { useState } from "react";
import classes from "../Earning.module.css";
import DashboardInput from "../../../../../components/DashboardInput/DashboardInput";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import CheckBox from "../../../../../components/CheckBox/CheckBox";
import { createRoyaltyTransition } from "../../../../Users/UsersActions";
import Modal from "../../../../../components/Modal/Modal";

function ClaimForm({ onCancel, remainingAmount }) {
  const [data, setData] = useState({ amount: 0, agree: false });
  const [show, setShow] = useState("");

  const handleTransferClick = () => {
    delete data.agree;
    createRoyaltyTransition(data).then((response) => {
      response.success ? setShow("Success") : setShow(response.data);
    });
  };

  return (
    <div className={classes.claimContainer}>
      <DashboardInput
        label={"Amount"}
        type="number"
        id="amount"
        inputStyle={{ width: "70%" }}
        placeholder={"Enter Amount to claim"}
        value={data.amount || ""}
        onKeyDown={(e) =>
          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
        }
        onChange={(e) => setData((p) => ({ ...p, amount: e.target.value }))}
      />

      <div className={classes.checkBoxAndButtonsWrapper}>
        <div className={classes.checkBoxWrapper}>
          <CheckBox
            defaultChecked={data.agree}
            onChange={(e) =>
              setData((p) => ({ ...p, agree: e.target.checked }))
            }
          />
          <label
            htmlFor="confirmDetails"
            className={classes.checkBoxDescription}
          >
            I agree to all the terms and conditions and proceed with the
            transfer using my saved bank details.
          </label>
        </div>
        <div className={classes.buttonsWrapper}>
          <PrimaryButton
            disabled={!data.agree || data.amount < 1000 || data.amount > remainingAmount}
            onClick={handleTransferClick}
          >
            Transfer
          </PrimaryButton>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        </div>
      </div>
      {show && (
        <Modal
          title={show === "Success" ? "Success" : "Error"}
          onClose={() => {
            setShow("");
            onCancel();
          }}
        >
          <div className={classes.content}>
            {show === "Success" ? (
              <div>
                <div>Your request has been submitted successfully.</div>
                <div>It will be processed within 7-8 working day.</div>
              </div>
            ) : (
              <div>{show}, Try again later sometime.</div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ClaimForm;
