import React, { useEffect, useState } from 'react';
import classes from './BankDetails.module.css'
import UserDashboardHeader from '../UserDashboardHeader/UserDashboardHeader';
import EmptyDialogue from '../EmptyDialogue/EmptyDialogue';
import bankDetails from '../../../../assets/dashboardImage/animation/bank-wallet-animation.json'
import DashboardInput from '../../../../components/DashboardInput/DashboardInput';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import { getBankDetails, saveBankDetail, deleteBank } from './BankActions';
import { useSelector } from 'react-redux';
import CheckBox from '../../../../components/CheckBox/CheckBox';

function BankDetails() {
    const [userBankDetails, setUserBankDetails] = useState([]);
    const [showAddBankForm, setShowAddBankForm] = useState(false);
    const [showEmptyDialogue, setShowEmptyDialogue] = useState(true);
    const [accountNumber, setAccountNumber] = useState('');
    const [confAccountNumber, setConfAccountNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [bankName, setBankName] = useState('');
    const [branch, setBranch] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [isError, setIsError] = useState(false);
    const [formMessage, setFormMessage] = useState('');
    const userDetails = useSelector(state => state.user);
    const [isAgree, setIsAgree] = useState(false);

    useEffect(() => {
        if (userBankDetails.length) {
            setShowAddBankForm(false);
            setShowEmptyDialogue(false);
        } else {
            setShowEmptyDialogue(true);
        }
    }, [userBankDetails]);

    useEffect(() => {
        setIsError(false);
        setFormMessage('');
    }, [accountNumber, confAccountNumber, accountHolderName, bankName, ifscCode]);


    function addBankHandler() {
        setShowEmptyDialogue(false);
        setShowAddBankForm(true);
    }

    function formCancelHandler() {
        setShowEmptyDialogue(true);
        setShowAddBankForm(false);
    }

    function clearFields() {
        setAccountNumber('');
        setConfAccountNumber('');
        setAccountHolderName('');
        setBankName('');
        setBranch('');
        setIfscCode('');
    }

    async function saveBankDetailHandler() {
        if (accountNumber && confAccountNumber && accountHolderName && bankName && branch && ifscCode) {
            if (accountNumber !== confAccountNumber) {
                setIsError(true);
                setFormMessage('Account number mismatch.');
            } else {
                const params = { accountNumber, accountHolderName, bankName, branch, ifscCode };
                const response = await saveBankDetail(params);
                if (response.id) {
                    setUserBankDetails([response]);
                    clearFields();
                }
            }
        } else {
            setIsError(true);
            setFormMessage('Please fill in all fields');
        }
    }

    async function deleteBankHandler() {
        const response = await deleteBank(userBankDetails[0]['id']);
        if (response.success) {
            setUserBankDetails([]);
        }
    }

    useEffect(() => {
        if (userDetails.user) {
            let filter = {};
            getBankDetails({
                ...filter,
                where: { ...filter?.where, userId: userDetails.user.userId }
            }).then(function (response) {
                let data = response.data;
                if (data.success) {
                    setUserBankDetails(data.data)
                }
            })
        }
    }, [userDetails]);

    const onlyAlphabets = (e, onchange) => {
        const inputValue = e.target.value;
        const onlyAlphabets = inputValue.replace(/[^A-Za-z\s]/g, '');
        e.target.value = onlyAlphabets;
        onchange(onlyAlphabets)
    };

    return (
        <div className={classes.main}>
            <UserDashboardHeader
                title="Bank Details"
                description="Manage your bank details here."
                disabled
                userDisabled
            />
            <div className={classes.container}>
                {userBankDetails.length > 0 ? (
                    <>
                        <div className={classes.bankDetails}>
                            <div className={classes.fieldWrapper}>
                                <p className={classes.detailsLabel}>Name</p>
                                <p className={classes.detailsContent}>{userBankDetails[0]['accountHolderName']}</p>
                            </div>
                            <div className={classes.fieldWrapper}>
                                <p className={classes.detailsLabel}>Account Number</p>
                                <p className={classes.detailsContent}>{userBankDetails[0]['accountNumber']}</p>
                            </div>
                            <div className={classes.fieldWrapper}>
                                <p className={classes.detailsLabel}>Bank Name</p>
                                <p className={classes.detailsContent}>{userBankDetails[0]['bankName']}</p>
                            </div>
                            <div className={classes.fieldWrapper}>
                                <p className={classes.detailsLabel}>Branch Name</p>
                                <p className={classes.detailsContent}>{userBankDetails[0]['branch']}</p>
                            </div>
                            <div className={classes.fieldWrapper}>
                                <p className={classes.detailsLabel}>IFSC Code</p>
                                <p className={classes.detailsContent}>{userBankDetails[0]['ifscCode']}</p>
                            </div>
                        </div>
                        <PrimaryButton style={{ width: 'fit-content' }} onClick={deleteBankHandler}>Delete bank</PrimaryButton>
                    </>
                ) : (showEmptyDialogue &&
                    <EmptyDialogue
                        animation={bankDetails}
                        descriptionText="Looks like you haven't added any bank details yet."
                        buttonText="Add bank details"
                        onClick={addBankHandler}
                    />
                )}

                {showAddBankForm &&
                    <div className={classes.addBankDetails}>
                        <DashboardInput
                            label={"Account Number"}
                            type="number"
                            id="accountNumber"
                            inputStyle={{ width: '70%' }}
                            placeholder={"Account Number"}
                            value={accountNumber}
                            onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                            onChange={(e) => setAccountNumber(e.target.value)}
                        />
                        <DashboardInput
                            label={"Confirm account Number"}
                            type="number"
                            id="confirmAccountNumber"
                            inputStyle={{ width: '70%' }}
                            onPaste={(e) => e.preventDefault()}
                            placeholder={"Confirm account Number"}
                            value={confAccountNumber}
                            onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                            onChange={(e) => setConfAccountNumber(e.target.value)}
                        />
                        <DashboardInput
                            label={"Account Holder Name"}
                            type="text"
                            id="accountHolderName"
                            inputStyle={{ width: '70%' }}
                            placeholder={"Account Holder Name"}
                            value={accountHolderName}
                            onChange={(e) => onlyAlphabets(e, setAccountHolderName)}
                        />
                        <DashboardInput
                            label={"Bank Name"}
                            type="text"
                            id="bankName"
                            onKeyPress={onlyAlphabets}
                            inputStyle={{ width: '70%' }}
                            placeholder={"Bank Name"}
                            value={bankName}
                            onChange={(e) => setBankName(e.target.value)}
                        />
                        <DashboardInput
                            label={"Branch Name"}
                            type="text"
                            onKeyPress={onlyAlphabets}
                            id="branchName"
                            inputStyle={{ width: '70%' }}
                            placeholder={"Branch Name"}
                            value={branch}
                            onChange={(e) => setBranch(e.target.value)}
                        />
                        <DashboardInput
                            label={"IFSC Code"}
                            type="text"
                            id="IFSCCode"
                            inputStyle={{ width: '70%' }}
                            placeholder={"IFSC Code"}
                            value={ifscCode}
                            onChange={(e) => setIfscCode(e.target.value)}
                        />
                        <div className={classes.checkBoxAndButtonsWrapper}>
                            <div className={classes.checkBoxWrapper}>
                                {/* <input className={classes.checkBox} type="checkbox" id="confirmDetails" onChange={e => setIsAgree(e.target.checked)} /> */}
                                <CheckBox onChange={e => setIsAgree(e.target.checked)} />
                                <label htmlFor='confirmDetails' className={classes.checkBoxDescription}>I agree to all the terms and conditions.</label>
                            </div>
                            <span className={`${classes.formMessage} ${isError ? classes.error : classes.success}`}>{formMessage}</span>
                            <div className={classes.buttonsWrapper}>
                                <PrimaryButton onClick={saveBankDetailHandler} disabled={!isAgree}>Add bank details</PrimaryButton>
                                <SecondaryButton onClick={formCancelHandler}>Cancel</SecondaryButton>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default BankDetails