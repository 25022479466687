import React, { useEffect, useState } from "react";
import classes from "./Transferred.module.css";
import UserDashboardHeader from "../UserDashboardHeader/UserDashboardHeader";
import Utils from "../../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { getTransferredEarnings } from "../../../Users/UsersActions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Transferred() {
  const dispatch = useDispatch();
  const {transferredEarnings, isTransferredEarningsFetched, user } = useSelector(state => state.user);
  const [isOpen, setIsOpen] = useState([]);

  useEffect(() => {
    if(!isTransferredEarningsFetched && user.userId) {
      getTransferredEarnings(dispatch, user.userId).then(function(res) { 
          if(res.success) {
            setIsOpen(res.data.map((ele) => false))
          }
       });
    }
  }, [isTransferredEarningsFetched, user, dispatch]);

  console.log(isOpen)

  return (
    <div className={classes.main}>
      <UserDashboardHeader
        title="Royalty earned"
        description="Check all your transferred royalties here."
        disabled={true}
      />
      <div className={classes.container}>
        <div className={classes.orderTableContainer}>
          <table className={classes.orderTable}>
            <thead className={classes.orderTableHeading}>
              <tr className={classes.tableRow}>
                <th className={classes.orderId}>ID</th>
                <th className={classes.amount}>Amount</th>
                <th className={classes.date}>Date</th>
                <th className={classes.statusColumnHeading}>Status</th>
                <th className={classes.actionColumnHeading}>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                Boolean(transferredEarnings.length) ? transferredEarnings.map((earning, idx) => {
                  return <tr className={classes.productRow} key={earning.id}>
                  <td className={classes.leftAlignedCell}>#0{idx+1}</td>
                  <td className={classes.centeredCell}>{Utils.getRupeeSymbol} {earning.amount}</td>
                  <td className={classes.centeredCell}>{Utils.getFormattedDate(earning.creationTime)}</td>
                  <td className={classes.centeredCell}><div className={classes.deliveryStatus}><div className={Utils.getClasses(classes.status, classes?.[earning.status])}>{earning.status}</div></div></td>
                  <td className={classes.centeredCell}><Link to={`/user/invoice/${earning.id}?type=earnings`} className={Utils.getClasses(earning.status !== "processed" ? classes.disabled : "" )}>View / Download invoices</Link></td>
                </tr>
                }):
                <tr>
                  <td colSpan="5" className={classes.centeredCell}>No Royalty Transferred yet.</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Transferred;
