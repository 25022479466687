import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-elastic-carousel";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import classes from "./HomeScreen.module.css";
import BookItem from "../../components/BookItem/BookItem";
import MainFooter from "../../components/MainFooter/MainFooter";
import { getHomeScreenBooks } from "../Books/BooksActions";
import aloozaCharacter from "../../assets/images/alooza-character.png";
import FeatureCard from "../../components/FeatureCard/FeatureCard";
import previewIcon from "../../assets/images/preview.svg";
import BookLifeCard from "../../components/BookLifeCard/BookLifeCard";
import createIcon from "../../assets/images/create.svg";
import publishIcon from "../../assets/images/publish.svg";
import earnIcon from "../../assets/images/earn.svg";
import designIcon from "../../assets/images/design.svg";
import oneIcon from "../../assets/images/number-one.svg";
import twoIcon from "../../assets/images/number-two.svg";
import threeIcon from "../../assets/images/number-three.svg";
import fourIcon from "../../assets/images/number-four.svg";
import fiveIcon from "../../assets/images/number-five.svg";
import booklifeBackground from "../../assets/images/booklife-background.png";
import themesIcon from "../../assets/images/carousel-themes.png";
import advancedFormattingIcon from "../../assets/images/carousel-formatting.png";
import limitedImagesIcon from "../../assets/images/carousel-images.png";
import layoutsIcon from "../../assets/images/carousel-layout.png";
import moneyIcon from "../../assets/images/carousel-money.png";
import reviewsIcon from "../../assets/images/carousel-review.png";
import Utils from "../../Utils";
import { getAuthors } from "../Users/UsersActions";
import StarAuthors from "./StarAuthors/StarAuthors";
import ReactPlayer from "react-player";
import HeroSection from "./HeroSection/HeroSection";
import EditorsChoice from "./EditorsChoice/EditorsChoice";
import CarouselItem from "./CarouselItem/CarouselItem";
import B2BInfo from "./B2BInfo/B2BInfo";
import SeoBanner from "./SeoBanner/SeoBanner";
import WelcomeModal from "../../components/Header/WelcomeModal/WelcomeModal";

const getRecognizedFeaturePoints = [
  {
    content: "Write About Your Fantasies",
  },
  {
    content: "Dedicate To Friends / Family",
  },
  {
    content: "Sell Your Autographed Copies",
  },
  {
    content: "Create Photo Book / Travel Book",
  },
  {
    content: "Use As Return Gift On Birthdays",
  },
  {
    content: "Write Your Poems And Articles",
  },
  {
    content: "Partner With Local Booksellers",
  },
  {
    content: "Sell On Global Website",
  },
  {
    content: "Write About Friendship Anthem",
  },
  {
    content: "Display Your Book in School Library",
  },
  {
    content: "Sell Your Book in Book Fairs",
  },
  {
    content: "Excel in English Exams",
  },
];

const bookLifePoints = [
  {
    title: "CREATE",
    description: "Select from 100+ themes to start a book",
    image: createIcon,
    left: false,
    headingColor: "rgb(81, 112, 194)",
    numberImage: oneIcon,
  },
  {
    title: "DESIGN",
    description: "Choose from ready-made page designs, covers, and images etc.",
    left: true,
    image: designIcon,
    headingColor: "rgb(50, 174, 198)",
    numberImage: twoIcon,
    imageStyle: {
      width: "9rem",
      height: "9rem",
    },
    bodyStyle: {
      position: "absolute",
      right: "18rem",
    },
  },
  {
    title: "PREVIEW",
    description: "Preview the e-book",
    image: previewIcon,
    headingColor: "rgb(8, 163, 152)",
    left: false,
    numberImage: threeIcon,
    bodyStyle: {
      position: "absolute",
      left: "17rem",
    },
  },
  {
    title: "PUBLISH",
    description: "Publish and order your copy",
    left: true,
    image: publishIcon,
    headingColor: "rgb(241, 137, 42)",
    numberImage: fourIcon,
    contentWidth: "15rem",
    bodyStyle: {
      position: "absolute",
      right: "18rem",
    },
  },
  {
    title: "EARN",
    description: "Sell your book and start earning",
    left: false,
    image: earnIcon,
    headingColor: "#6f2f87",
    numberImage: fiveIcon,
    imageStyle: {
      width: "6rem",
      height: "6rem",
    },
    bodyStyle: {
      position: "absolute",
      left: "26rem",
    },
  },
];

const whoAreWePoints = [
  {
    image: themesIcon,
    title: "100+ book themes",
    highlightColor: "rgb(242, 110, 20)",
    isCurrent: true,
  },
  {
    image: advancedFormattingIcon,
    title: "Advanced formatting",
    highlightColor: "rgb(5, 109, 183)",
    isCurrent: true,
  },
  {
    image: limitedImagesIcon,
    title: "Unlimited collection of images",
    highlightColor: "rgb(52, 128, 176)",
    isCurrent: true,
  },
  {
    image: layoutsIcon,
    title: "10,000+ Layouts",
    highlightColor: "rgb(82, 186, 195)",
    isCurrent: true,
  },
  {
    image: moneyIcon,
    title: "Earn money through Author Royalties",
    highlightColor: "rgb(238, 187, 17)",
    isCurrent: true,
  },
  {
    image: reviewsIcon,
    title: "Professional review before publishing",
    highlightColor: "rgb(249, 159, 16)",
    isCurrent: true,
  },
  {
    display: "none",
  },
];

function HomeScreen() {
  const ratedBooks = useSelector((state) => state.books.homeScreenBooks) || [];
  const history = useHistory();
  const dispatch = useDispatch();
  const starAuthors = useSelector((state) => state.user.starAuthors);

  useEffect(() => {
    if (!ratedBooks.length) {
      getHomeScreenBooks(dispatch, `overallRating DESC`);
    }
    getAuthors(dispatch);
  }, [dispatch]);

  const [currentItem, setCurrentItem] = useState(0);

  const carouselRef = useMemo(() => React.createRef(), []);
  const totalPages = Math.ceil(whoAreWePoints.length / 2);
  const resetTimeout = useRef(null);

  const onCurrentChange = useCallback((current, index) => {
    clearTimeout(resetTimeout.current);
    setCurrentItem(current.index);
    if (index + 1 === totalPages) {
      resetTimeout.current = setTimeout(() => {
        carouselRef.current?.goTo?.(0);
        setCurrentItem(0);
      }, 2000);
    }
  }, [carouselRef, totalPages]);

  const memoizedCarouselItems = useMemo(() => {
    return whoAreWePoints.map((item, idx) => (
      <CarouselItem
        key={idx}
        item={item}
        idx={idx}
        currentItem={currentItem}
      />
    ));
  }, [currentItem]);

  return (
    <div className={classes.mainContainer}>
      <WelcomeModal />
      <HeroSection />
      <EditorsChoice />
      <div className={classes.whoAreWeMain}>
        <h2 className={classes.whoAreWeHeading}>How are we different?</h2>
        <div className={classes.carousel}>
          <Carousel
            ref={carouselRef}
            enableAutoPlay
            autoPlaySpeed={2000}
            showArrows={false}
            itemsToShow={2}
            onNextEnd={onCurrentChange}
          >
            {memoizedCarouselItems}
          </Carousel>
        </div>
      </div>
      <SeoBanner />
      <div className={classes.demoVideoSection}>
        <div className={classes.videoWrapper}>
          <ReactPlayer
            url={
              "https://www.youtube.com/embed/DWd2_N8QJJc?si=XQyg8B6he0shk7Ja"
            }
            fallback={<div>Loading...</div>}
            width="100%"
            height="100%"
            light
            controls
          />
        </div>
      </div>
      <B2BInfo />
      <StarAuthors authors={starAuthors} />
      <div className={classes.bookLifeMain}>
        <div className={classes.bookLifeHeadingWrapper}>
          <div className={classes.bookLifeHeading}>
            One-click and your dream book
          </div>
          <div className={classes.bookLifeHeading}>turns into reality!</div>
        </div>
        <div className={classes.bookLifeCardsContainer}>
          {bookLifePoints.map((item, idx) => {
            return (
              <BookLifeCard
                key={idx}
                title={item.title}
                description={item.description}
                image={item.image}
                headingColor={item.headingColor}
                numberImage={item.numberImage}
              />
            );
          })}
        </div>
        <div className={classes.booklifeBackgroundImageWrapper}>
          <img
            src={booklifeBackground}
            className={classes.booklifeBackgroundImage}
            alt=""
          />
        </div>
      </div>
          {/*       <div className={classes.getRecognizedAsAuthorMain}>
        <div className={classes.getRecognizedAsAuthorHeading}>
          <div className={classes.aloozaHeroImage}>
            <img src={aloozaCharacter} alt="aloozaCharacter" />
          </div>
          <div className={classes.getRecognizedAsAuthorTitle}>
            <div>
              Showcase your hidden talent and get recognized as an Author
            </div>
          </div>
        </div>
        <div className={classes.getRecognizedAsAuthorContainer}>
          {getRecognizedFeaturePoints.map((item, idx) => {
            return <FeatureCard key={idx} content={item.content} />;
          })}
        </div>
      </div> */}

      <MainFooter />
    </div>
  );
}

export default HomeScreen;
