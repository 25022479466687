import React from 'react'
import bookalooza from "../../../../assets/Bookalooza-logo-blackText.png"
import classes from "./Invoice.module.css"

function InvoiceThanks({type}) {
  return (
    <div className={classes.orderConfirmation}>
    <div className={classes.container}>
      <div className={classes.brand}>
        <img src={bookalooza} alt="brand" />
      </div>
      {type !== "earnings" && <h1 className={classes.title}>Thanks for your order!</h1>}
    </div>
  </div>
  )
}

export default InvoiceThanks