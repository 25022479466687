import axios from "../../../axios";
import baseAxios from "axios";
import Utils from "../../Utils";
import { usersActions } from "./UsersSlice";
import Consts from "../../Consts";
import { fetchCartItems, getCouponDetails } from "../AddCartScreen/AddCartSlice";

export function createUser(data) {
    return axios({
        url: "/designer/user/create",
        method: "post",
        data: data,
        responseType: "json",
    }).then(e => e.data);
}

export function signInUser(data) {
    return axios({
        url: "/designer/user/login",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                // let newdata = data;
                // newdata.id = response.data.user.id;
                // delete newdata.password;
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function logout(history, dispatch) {
    return axios({
        url: "/designer/user/logout",
        method: "get"
    }).then(e => {
        localStorage.removeItem("isLoggedIn");
        dispatch(usersActions.fetchProfile({ user: null }));
        window.location.href = `${Consts.BASE_URL}/sign-in`;
        // history?.push(`${Consts.BASE_URL}/sign-in`);
    });
}

export function forgotPassword(data) {
    return axios({
        url: "/designer/user/forgotpassword",
        method: "post",
        data: data,
        responseType: "json",
    }).then(e => e.data);
}

export function updatePassword(data) {
    data.userId = Utils.getUserId();
    return axios({
        url: "/designer/user/updatePassword",
        method: "put",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            return response;
        }).catch((error) => {
            return error;
        });
}

export function verifyUser(data) {
    return axios({
        url: "/designer/user/verify",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                localStorage.setItem("isLoggedIn", "true");
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function resendCode(data) {
    return axios({
        url: "/designer/user/resend",
        method: "post",
        data: data,
        responseType: "json",
    })
        .then((response) => {
            if (response.data.success) {
                // let newdata = data;
                // newdata.id = response.data.user.id;
                // delete newdata.password;
            }
            return response;
        }).catch((error) => {
            return error;
        });
}

export function updateProfile(data, photo, dispatch) {
    let formData = new FormData();

    formData.append("userDetails", JSON.stringify(data));
    if (photo) {
        formData.append("photo", photo);
    }

    return axios({
        url: "/designer/user/updateProfile",
        method: "post",
        data: formData,
        responseType: 'json',
        headers: {
            'content-type': 'multipart/form-data'
        },
    }).then((response) => {
        if (response.data.success) {
            dispatch(usersActions.fetchProfile({ user: response.data.data }));
        }
        return response;
    }).catch((error) => {
        return error;
    });
}

export function fetchProfile(dispatch) {
    return baseAxios({
        url: "/designer/user/profile"
    }).then((response) => {
        const data = response.data;
        if (data.success) {
            delete data.success;
            dispatch(usersActions.fetchProfile({ user: data.data }));
        }
        return response;
    });
}

export function fetchNotifications(dispatch) {
    let filter = {
        sortBy: `time DESC`
    }
    return axios({
        url: `/designer/notifications/detailed?filter=${encodeURIComponent(JSON.stringify(filter))}`
    }).then((response) => {
        const data = response.data;
        if (data.success) {
            delete data.success;
            dispatch(usersActions.fetchNotifications({ notifications: data.data }));
        }
        return response;
    });
}


export function fetchEarnings(filter) {
    const createOrderUrl = `/designer/earnings/bookWise`;
    return axios({
        url: createOrderUrl,
        method: "get",
    }).then((response) => {
        if (response.status === 200) {
            return response;
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}

export async function getAuthors(dispatch) {
    const actionUrl = `/designer/user/starAuthors`;
    return axios({
        url: actionUrl,
        method: "get",
    }).then((response) => {
        if (response.status === 200) {
            let data = response.data;
            if (data.success) {
                dispatch(usersActions.fetchStarAuthor({ authors: data.data || [] }))
            }
        } else {
            throw 'Something went wrong. Please try again';
        }
    });
}

export async function userProfile(dispatch) {
    return fetchProfile(dispatch).then(e => {
        dispatch(fetchCartItems());
        dispatch(getCouponDetails());
        fetchNotifications(dispatch)
        return e.data;
    })
}

export function getSchoolDetails(dispatch, id) {
    return axios({
        url: `/designer/school/link/${id}`,
    })
        .then((res) => {
            const data = res.data;
            if (data.success) {
                dispatch(usersActions.fetchSchoolDetails(data.data));
            }
        })
        .catch((err) => err);
}

export function createRoyaltyTransition(data) {
    return axios({
        url: "/designer/redemption",
        method: "post",
        responseType: "json",
        data
    }).then(e => e.data).catch(e => {
        throw e;
    });
}

export function fetchTransferredEarnings(filter = {}) {
    return axios({
        url: `/designer/redemption?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: "get",
        responseType: "json",
    })
}

export function fetchTransferredEarningById(id) {
    return axios({
        url: `/designer/redemption/${id}`,
        method: "get",
        responseType: "json",
    })
}

export function getTransferredEarnings(dispatch, id) {
    let filter = {
        where: {
            userId: id
        }
    }
    return fetchTransferredEarnings(filter).then(e => {
        if(e.data.success){
            dispatch(usersActions.fetchTransferredEarnings(e.data.data));
            return e.data;
        }
    });
}