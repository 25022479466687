import { useEffect, useRef, useState } from "react";
import Utils from "../../Utils";
import classes from "./AnimatedList.module.css";

function AnimatedList({
  listItems = [],
  className,
  itemClassName,
  listType = "ul",
}) {
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the container is in view
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    const currentRef = containerRef.current;
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const List = listType;

  return (
    <List
      className={Utils.getClasses(
        classes.animatedList,
        className,
        isInView && classes.inView
      )}
      ref={containerRef}
    >
      {listItems.map((item, index) => (
        <li
          key={index}
          className={Utils.getClasses(classes.listItem, itemClassName)}
          style={{
            animationDelay: `${index * 0.2}s`,
            transitionDelay: `${index * 0.25}s`,
          }}
        >
          {index + 1}. {item}
        </li>
      ))}
    </List>
  );
}

export default AnimatedList;
