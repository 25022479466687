import React, { useEffect, useState } from "react";
import classes from "../Header.module.css";
import ModalDialog from "../../Dialog/Dialog";
import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";
import mic from "../../../assets/images/Mic.png";
import youTube from "../../../assets/icons/youtube.svg";

function WelcomeModal() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let timeOut = setTimeout(() => {
        setShowModal(true);
    }, 5000);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <>
      {showModal ? (
        <ModalDialog
          maxWidth={"md"}
          title={" "}
          onClose={() => setShowModal(false)}
          content={
            <div className={classes.welcomeModalMain}>
              <div className={classes.welcomeModalImageContainer}>
                <img src={mic} alt={mic} />
              </div>
              <div className={classes.welcomeModalContent}>
                <h3>Hey Star Author!</h3>
                <p>
                  Have you written your book on Bookalooza? Book your podcast
                  with us, shine on <img src={youTube} alt={youTube} width={24} height={24} style={{marginBottom: "-5px"}} /> YouTube, and sell your books like never
                  before! Ready to share your story?
                </p>
                <div className={classes.welcomeModalFooter}>
                  <div>
                    <SecondaryButton className={classes.welcomeSecondaryBtn} onClick={() => setShowModal(false)}>
                      Not Yet!
                    </SecondaryButton>
                  </div>
                  <div>
                    <PrimaryButton
                     className={classes.welcomePrimaryBtn}
                      onClick={() =>
                        window.open(
                          "https://docs.google.com/forms/d/e/1FAIpQLSdTbzzmWvphJ6EnT1FeAKtoghJmWOk-DG2mFAF8i1vm0WNIag/viewform"
                        )
                      }
                    >
                      Yes, Book my Podcast
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default WelcomeModal;
