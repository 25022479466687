import React from "react";
import classes from "./Static.module.css";
import StaticHeader from "./StaticHeader";
import pricingAndRoyaltiesAnimation from "../../assets/staticScreenAnimation/pricing-and-royalties.json";
import Divider from "../../components/Divider/Divider";
import StaticContent from "./StaticContent";
import BackButton from "../../components/Buttons/BackButton";
import MainFooter from "../../components/MainFooter/MainFooter";
import Utils from "../../Utils";

const content = [
  {
    title: "Book Price Calculator",
    description: [
      <>
        <div className={classes.tableWrapper}>
          <table className={classes.bookPriceTable}>
            <thead>
              <tr>
                <th className={classes.pages}>Total no. of Book Pages</th>
                <th className={classes.centerCellHeading}>Title</th>
                <th className={classes.centerCellHeading}>Binding</th>
                <th className={classes.price}>MRP</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tableCell}>24-32</td>
                <td className={classes.tableCell}>4 Page</td>
                <td className={classes.tableCell}>Center Pin</td>
                <td className={classes.tableCell}>350</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>40-48</td>
                <td className={classes.tableCell}>4 Page</td>
                <td className={classes.tableCell}>Center Pin</td>
                <td className={classes.tableCell}>375</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>48-64</td>
                <td className={classes.tableCell}>4 Page</td>
                <td className={classes.tableCell}>Perfect</td>
                <td className={classes.tableCell}>400</td>
              </tr>
              <tr>
                <td className={classes.tableCell}>64 & Above </td>
                <td className={classes.tableCell}>4 Page</td>
                <td className={classes.tableCell}>Perfect</td>
                <td className={classes.tableCell}>400 + 3 per extra page</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>,
      <p className={classes.italicPara}>
        (<b>Every extra page will cost Rs 3 per page</b>)
      </p>,
      <p className={classes.italicPara}>
        Every Book must include page count which should be multiple of 8.
      </p>,
    ],
  },
  {
    title: "Book Quantity discount (Includes 4 Pages of Title Cover)",
    description: [
      <>
        <div className={classes.tableWrapper}>
          <table className={classes.bookPriceTable}>
            <thead>
              <tr>
                <th
                  className={Utils.getClasses(
                    classes.tablePurpleHeading,
                    classes.type
                  )}
                >
                  Type
                </th>
                <th
                  className={Utils.getClasses(
                    classes.tablePurpleHeading,
                    classes.discount
                  )}
                >
                  Discount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tableCell}>1. Author Code Discount</td>
                <td className={classes.tableCell}>10% discount on MRP</td>
              </tr>
              <tr>
                <td className={classes.quantityCellLabel}>
                  2. Quantity Discount
                </td>
                <td className={classes.quantityCell} rowSpan={5}>
                  <div>1-09 = No discount</div>
                  <div>10-19 = 5% Discount</div>
                  <div>20-49 = 10 % Discount</div>
                  <div className={classes.mostRecommendedRow}>
                    <span className={classes.mostRecommendedLabel}>
                      Most Recommended
                    </span>
                    50-99 = 15% Discount
                  </div>
                  <div>100 or more = 20% Discount</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>,
    ],
  },
  {
    title: "Royalty on each sold book",
    description: [
      <>
        <div className={classes.tableWrapper}>
          <table className={classes.bookPriceTable}>
            <thead>
              <tr>
                <th
                  className={Utils.getClasses(
                    classes.tablePurpleHeading,
                    classes.MRP
                  )}
                >
                  MRP (Depends on Pages)
                </th>
                <th
                  className={Utils.getClasses(
                    classes.tablePurpleHeading,
                    classes.royalty
                  )}
                >
                  Royalty (Per Book)
                </th>
                <th
                  className={Utils.getClasses(
                    classes.tablePurpleHeading,
                    classes.amount
                  )}
                >
                  Amount (Per Book)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.tableCell}>&#8377; 200</td>
                <td className={classes.tableCell}>10%</td>
                <td className={classes.tableCell}>&#8377; 20</td>
              </tr>
            </tbody>
          </table>
        </div>
      </>,
    ],
  },
  {
    title: "ISBN allocation & Global Presence",
    description: [
      "Please find below the minimum criteria for ISBN allotment and Amazon Global Upload. Before meeting the below requirements, the book sold will be considered as author copies.",
      <>
        <table className={classes.bookPriceTable}>
          <thead>
            <tr>
              <th
                className={Utils.getClasses(
                  classes.tablePurpleHeading,
                  classes.type
                )}
              >
                ISBN allocation
              </th>
              <th
                className={Utils.getClasses(
                  classes.tablePurpleHeading,
                  classes.discount
                )}
              >
                Minimum 50 book copies sold
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tableCell}>
                Amazon / Flipkart Global Upload
              </td>
              <td className={classes.tableCell}>Minimum 50 book copies sold</td>
            </tr>
          </tbody>
        </table>
      </>,
    ],
  },
  {
    title: "Disclaimer",
    description: [
      `The services provided by the company are offered on an "as is" and "as available" basis. The company does not make any guarantees or warranties, whether explicit or implicit, regarding the operation of these services, or the accuracy, completeness, reliability, security, quality, or availability of the information, content, or materials included therein. By using these services, their content, or any services or items obtained from the company, you acknowledge and agree that you assume all risks associated with it.`,
      `Neither the company nor any person associated with the company provides any warranty or representation concerning the accuracy, reliability, completeness, error-free nature, uninterrupted availability, or freedom from viruses or other harmful components of the services. Furthermore, the company and its affiliates do not guarantee that the services, their content, or any services or items obtained through the services will meet your specific needs or expectations.`,
      `The company explicitly disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, non-infringement, and fitness for a particular purpose. This disclaimer of warranties applies to the fullest extent permitted by applicable law and does not affect any warranties that cannot be excluded or limited under such laws.`,
    ],
  },
];

function PricingAndRoyalties() {
  return (
    <>
      <div className={classes.main}>
        <div className={classes.backButtonWrapper}>
          <BackButton className={classes.backButton} />
        </div>
        <div className={classes.container}>
          <StaticHeader
            title={"Pricing And Royalties"}
            description={"Read our terms below to learn more"}
            animation={pricingAndRoyaltiesAnimation}
          />
          <Divider style={{ marginBottom: "2rem" }} />
          <div className={classes.contentWrapper}>
            {content?.map((item, idx) => {
              return (
                <StaticContent
                  key={idx}
                  title={item.title}
                  description={item.description}
                />
              );
            })}
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
}

export default PricingAndRoyalties;
